




















































import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { SettingsModule } from '../../plugins/store';

const settingsStore: SettingsModule = getModule(SettingsModule);

@Component
export default class OtaListView extends Vue {
  private items: Record<string, string> = {
    firmware: '$vuetify.ota.FIRMWARE',
    jobs: '$vuetify.ota.UPDATES'
  };

  private get showConsole(): boolean {
    return settingsStore.console;
  }
}
